import React, {useContext} from "react"
import Layout from "../components/layout/layout"
import {SiteContext} from "../context/site-context";

const NotFoundPage = () => {

  const siteContext = useContext(SiteContext);

  return(
    <Layout>
      <div className="page-404">
        <h1>OOPS!</h1>
        <p>Pagina niet gevonden. Jammer maar helaas. Shit happens.</p>
      </div>
    </Layout>
  );
};

export default NotFoundPage
